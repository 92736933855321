var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('monitoringQuestion.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('monitoringQuestion.pageTitle'),
                active: true
            }
        ]}}),_c('div',{staticClass:"row"},[_c('ts-loading-banner',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"col-md-12"},[_c('ts-panel',[_c('ts-panel-wrapper',[_c('question-info',{ref:"question_info",model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-text-right tw-space-x-2"},[_c('ts-button',{on:{"click":function($event){return _vm.$router.push({
                                            name: 'monitoring-question'
                                        })}}},[_vm._v(" "+_vm._s(_vm.$t("cancel")))]),_c('ts-button',{attrs:{"waiting":_vm.saving,"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("update")))])],1)])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }